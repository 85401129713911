import {  put, call, takeEvery } from "redux-saga/effects";
import "whatwg-fetch";
import * as GlobalActions from "../../actions";
import * as Actions from "./store/actions";
import ActionTypes from "../../constants";
import * as Apis from "./apis";
import { wrapByLoader } from "../../utils";

const sendEmail = ({payload, products }) =>
  wrapByLoader(function* () {
    try {
      const response = yield call(Apis.sendEmailApi, payload);
      if (!response.error) {
        yield put(Actions.sendEmailSucceeded(products));
      } else {
        yield put(GlobalActions.errorOccured(response));
      }
    } catch (err) {
      yield put(GlobalActions.networkErrorOccured(err));
    }
  });

export default [takeEvery(ActionTypes.SEND_EMAIL, sendEmail)];
