// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
import "@firebase/messaging";
import urls from "./js/config/urls.js";

// TODO: Use Azure Notification HUB
// import "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBMgvqOHJ__AMDWQKCrXkAdg6TIABEWujg",
  authDomain: "pf-distributor.firebaseapp.com",
  databaseURL: "https://pf-distributor.firebaseio.com",
  projectId: "pf-distributor",
  storageBucket: "pf-distributor.appspot.com",
  messagingSenderId: "29917240971",
  appId: "1:29917240971:web:6561cf6ce96a27e9577e8e",
  measurementId: "G-WPQ6Q5RZGB",
};

let messaging;

export const initializeFirebase = () => {
  firebase.initializeApp(firebaseConfig);
  messaging = firebase.messaging();
  obsRefreshToken();
  handleIncomingMessages();
};

async function postData(url="", data={}) {
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
  return response;
}

async function sendTokenToServer(currentToken) {
  try {
    await postData(urls.registerNewDevice, {
      "currentToken": currentToken,
      "deviceToken": currentToken
    });
  } catch (err){
    console.error(err);
  }
}

function updateUIForPushEnabled(currentToken) {}
// Saves the messaging device token to the datastore.
function saveMessagingDeviceToken() {
  console.log("saveMessagingDeviceToken");
  messaging
    .getToken()
    .then(function (currentToken) {
      if (currentToken) {
        console.log("Got FCM device token:", currentToken);
        sendTokenToServer(currentToken);
        updateUIForPushEnabled(currentToken);
      } else {
        console.log("requestPermissionAgain");
        // Need to request permissions to show notifications.
        requestNotificationsPermissions();
      }
    })
    .catch(function (error) {
      console.error("Unable to get messaging token.", error);
    });
}

// Requests permissions to show notifications.
export const requestNotificationsPermissions = async () => {
  console.log("Requesting notifications permission...");
  messaging
    .requestPermission()
    .then(function () {
      // Notification permission granted.
      saveMessagingDeviceToken();
    })
    .catch(function (error) {
      console.error("Unable to get permission to notify.", error);
    });
};

// Callback fired if Instance ID token is updated.
function obsRefreshToken() {
  messaging.onTokenRefresh(() => {
    messaging
      .getToken()
      .then((refreshedToken) => {
        console.log("Token refreshed.");
        // Indicate that the new Instance ID token has not yet been sent to the
        // app server.
        // setTokenSentToServer(false);
        // Send Instance ID token to app server.
        // sendTokenToServer(refreshedToken);
        // ...
      })
      .catch((err) => {
        console.log("Unable to retrieve refreshed token ", err);
        // showToken('Unable to retrieve refreshed token ', err);
      });
  });
}

function handleIncomingMessages() {
  messaging.onMessage((payload) => {
    console.log("Message received", payload);
    alert(payload.notification.body);
  });
}
