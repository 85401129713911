import appActionTypes from "../containers/App/store/constants";
import productsActionTypes from "../containers/Products/store/constants";
import productActionTypes from "../containers/ProductDetails/store/constants";
import settingsActionTypes from "../containers/Settings/store/constants";

const ActionTypes = {
  ...appActionTypes,
  ...productsActionTypes,
  ...productActionTypes,
  ...settingsActionTypes,
};

export default ActionTypes;
