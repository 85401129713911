import { hot } from "react-hot-loader/root";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AppComponent from "./components";
import { updateViewport } from "./../../actions";
import { sendEmail } from "./../App/store/actions";

const mapStateToProps = ({ app, notification }) => ({
  isLoading: app.isLoading,
  menuKey: app.menuKey,
});

const mapDispatchToProps = {
  updateViewport,
  sendEmail
};

const App = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(hot(AppComponent))
);

export default App;
