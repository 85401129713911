import ActionTypes from "../../../constants";

const initialState = {
  products: null,
};

const products = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.HYDRATE_LOCAL_PRODUCTS:
    case ActionTypes.FETCH_PRODUCTS_SUCCEEDED: {
      const { products } = action;
      return { ...state, products };
    }

    default: {
      return state;
    }
  }
};

export default products;
