import { combineReducers } from "redux";
import app from "../containers/App/store/reducer";
import err from "../containers/Common/ErrorHandler/reducer";
import products from "../containers/Products/store/reducer";
import product from "../containers/ProductDetails/store/reducer";
import settings from "../containers/Settings/store/reducer";

export const rootReducer = combineReducers({
  app,
  err,
  products,
  product,
  settings,
});
