import ActionTypes from "../../../constants";

export const fetchProducts = () => ({
  type: ActionTypes.FETCH_PRODUCTS,
});

export const fetchProductsSucceeded = (products) => ({
  type: ActionTypes.FETCH_PRODUCTS_SUCCEEDED,
  products,
});

export const hydrateLocalProducts = (products) => ({
  type: ActionTypes.HYDRATE_LOCAL_PRODUCTS,
  products,
});
