import React, { Suspense } from "react";
import { Switch, Route, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBell, faWifi } from "@fortawesome/free-solid-svg-icons";
import { getViewport } from "../../../utils";
import "../style.scss";
import Loader from "../../Common/Loader";
import { Button } from "antd";
import { requestNotificationsPermissions } from "../../../../pushNotification.js";

const Sidebar = React.lazy(() => import("../../Common/Sidebar"));
const Settings = React.lazy(() => import("../../Settings"));
const ProductDetails = React.lazy(() => import("../../ProductDetails"));
const Products = React.lazy(() => import("../../Products"));

const logoPath = "images/logo-javra.svg";

class AppComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebar: false,
      isOnline: navigator.onLine,
    };
    this.resizeTracker = null;
  }
  componentDidMount() {
    window.addEventListener("resize", this.resizeHandler, false);
    this.resizeHandler();
    window.addEventListener("online", this.updateConnectionStatus, false);
    window.addEventListener("offline", this.updateConnectionStatus, false);
  }
  componentDidUpdate(prevProps) {
    const { isLoading } = this.props;

    if (isLoading > 0 && isLoading !== prevProps.isLoading) {
      this.addLoadingToBody();
    }
    if (isLoading === 0 && isLoading !== prevProps.isLoading) {
      this.removeLoadingFromBody();
    }
  }

  addLoadingToBody = () => {
    document.getElementsByTagName("body")[0].classList.add("loading");
  };

  removeLoadingFromBody = () => {
    document.getElementsByTagName("body")[0].classList.remove("loading");
  };

  updateConnectionStatus = () => {
    const isOnline = window.navigator.onLine;
    if (isOnline) {
      document.body.classList.remove("offline");
    } else {
      document.body.classList.add("offline");
    }
    this.setState({ isOnline });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeHandler, false);
    clearTimeout(this.NotificationTracker);
    window.removeEventListener("online", this.updateConnectionStatus, false);
    window.removeEventListener("offline", this.updateConnectionStatus, false);
  }
  updateDimension = () => {
    const viewport = getViewport();
    this.props.updateViewport(viewport);
  };
  resizeHandler = () => {
    clearTimeout(this.resizeTracker);
    this.resizeTracker = setTimeout(() => this.updateDimension, 16);
  };
  handleClick = (e) => {
    this.setState({
      current: e.key,
    });
  };
  toggleSidebar = () => {
    this.setState({ sidebar: !this.state.sidebar });
  };
  render() {
    return (
      <div className="app-wrapper">
        <div className="header-wrapper">
          <header className="header">
            <Button onClick={this.toggleSidebar} className="sidebar-menu" size="small">
              <FontAwesomeIcon color="#1bc187" icon={faBars} />
            </Button>
            <span className="logo">
              <Link to="/">
                <img src={logoPath} alt="Javra Store" />
              </Link>
            </span>
            <Button
              onClick={requestNotificationsPermissions}
              className="notification-button"
              shape="circle"
              size="small"
            >
              <FontAwesomeIcon color="#1bc187" icon={faBell} />
            </Button>
            <div className="offline-message">
              <span>No internet connection!</span>
              <span className="offline-icon">
                <FontAwesomeIcon color="#01447b" icon={faWifi} size="lg" className="no-signal" />
              </span>
            </div>
          </header>
        </div>
        {/* {this.state.sidebar && ( */}
        <Suspense fallback={<div>Loading...</div>}>
          <Sidebar toggleSidebar={this.toggleSidebar} sidebar={this.state.sidebar}></Sidebar>
        </Suspense>
        {/* )} */}
        <div className="main-wrapper">
          <div className="main">
            <Switch>
              <Route exact path="/">
                <Suspense fallback={<div>Loading...</div>}>
                  <Products isOnline={this.state.isOnline} />
                </Suspense>
              </Route>
              <Route exact path="/products">
                <Suspense fallback={<div>Loading...</div>}>
                  <Products isOnline={this.state.isOnline} />
                </Suspense>
              </Route>
              <Route exact path="/products/:productSKU">
                <Suspense fallback={<div>Loading...</div>}>
                  <ProductDetails isOnline={this.state.isOnline} />
                </Suspense>
              </Route>
              <Route exact path="/settings">
                <Suspense fallback={<div>Loading...</div>}>
                  <Settings />
                </Suspense>
              </Route>
            </Switch>
          </div>
        </div>
        <div className="footer">Copyright 2021 Javra Software.</div>
        {this.props.isLoading > 0 && <Loader />}
      </div>
    );
  }
}

export default AppComponent;
