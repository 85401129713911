import ActionTypes from "../../../constants";

const initialState = {
  product: null,
};

const product = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_PRODUCT_SUCCEEDED: {
      const { product } = action;
      return { ...state, product };
    }
    case ActionTypes.RESET_PRODUCT: {
      return { ...state, product: null };
    }
    default: {
      return state;
    }
  }
};

export default product;
