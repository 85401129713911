
import ActionTypes from "../../../constants";
const initialState = {
  isLoading: 0,
  dateFormat: "MM-DD-YYYY",
  viewport: {
    height: 0,
    width: 0,
  },
  userInfo: null,
  menuKey: null,
  scanEmailSucceeded: false,
};
const app = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_START: {
      return { ...state, isLoading: state.isLoading + 1 };
    }
    case ActionTypes.FETCH_END: {
      return { ...state, isLoading: state.isLoading - 1 };
    }
    case ActionTypes.SET_USER_INFO: {
      const { userInfo } = action;
      return { ...state, userInfo };
    }
    case ActionTypes.SET_MENU_KEY: {
      const { menuKey } = action;
      return { ...state, menuKey };
    }
    case ActionTypes.UPDATE_VIEWPORT: {
      const { viewport } = action;
      return {
        ...state,
        viewport,
      };
    }
    case ActionTypes.SEND_EMAIL_SUCCEEDED: {
      return {
        ...state,
        scanEmailSucceeded: true,
      };
    }
    case ActionTypes.RESET_SEND_EMAIL_SUCCEEDED_FLAG: {
      return {
        ...state,
        scanEmailSucceeded: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default app;
