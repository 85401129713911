import "./js/config/hotLoaderConfig";
import "@babel/polyfill";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import configureStore from "./js/config/configureStore";
import App from "./js/containers/App";
import * as serviceWorker from "./serviceWorker";
import { initializeFirebase } from "./pushNotification";

import "./styles/style.scss";

// mount it on the Store
const store = configureStore();

const renderApp = () =>
  render(
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>,
    document.getElementById("root")
  );

renderApp();

initializeFirebase();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
