import ActionTypes from "../constants";

// global actions
export const fetchStart = () => ({
  type: ActionTypes.FETCH_START,
});
export const fetchEnd = () => ({
  type: ActionTypes.FETCH_END,
});

export const resetError = () => ({
  type: ActionTypes.RESET_ERROR,
});

export const updateViewport = (viewport) => ({
  type: ActionTypes.UPDATE_VIEWPORT,
  viewport,
});

export const errorOccured = (err) => ({
  type: ActionTypes.ERROR_OCCURED,
  err,
});
export const silentErrorOccured = (err) => ({
  type: ActionTypes.SILENT_ERROR_OCCURED,
  err,
});

export const networkErrorOccured = (err) => ({
  type: ActionTypes.NETWORK_ERROR_OCCURED,
  err,
});

export const setMenuKey = (menuKey) => ({
  type: ActionTypes.SET_MENU_KEY,
  menuKey,
});
