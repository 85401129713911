// a configurable base url so that it can fit into any deployment system;
// namely development, test/uat and production
let baseUrl = "https://azfunc-pfd-product-rnd-lab.azurewebsites.net/api";
let notificationServiceAPI = "https://azfunc-pfd-notification-dev.azurewebsites.net/api";

// url mapper for all the apis
const urls = {
  products: `${baseUrl}/ListProducts?code=3Y/VixvGWtcG3XrLe5ejPGJb2aYvwa8pZRIHaW0hjuxDnhR2Ep/xkg==`,
  product: `${baseUrl}/GetProduct?code=JZwycRavaYHq7rHARtnFkMqiMGlGGa4alTBGCDWjgeqdRala5uGxzg==`,
  registerNewDevice: `${notificationServiceAPI}/createDeviceToken?code=fZ12rPoxnV9cvELwFsfcHpMTjNAOkLavmuCdIk1bVWQo72KG9M2QnA==`,
  stock: `${baseUrl}/UpdateArticle?code=UtWUOK3xn4bRAJSTaWvUfYxOywRDil2HKwgwpvJrKrNIaEz6b1WuWA==`,
  // TODO: udate Email
  sendEmail: `https://prod-95.westeurope.logic.azure.com:443/workflows/eaf28a1e1c8d4711b767c10714185ed7/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=g7P1eGu2u18ynoi_8C_ulTpC6OTFQVgxnwFNAbJjd6E`,
};
export default urls;
