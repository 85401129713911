import { takeLatest, put, call } from "redux-saga/effects";
import "whatwg-fetch";
import * as GlobalActions from "../../actions";
import * as Actions from "./store/actions";
import ActionTypes from "../../constants";
import * as Apis from "./apis";
import { wrapByLoader } from "../../utils";

const fetchSettings = () =>
  wrapByLoader(function* () {
    try {
      const response = yield call(Apis.fetchSettingsApi);
      if (!response.error) {
        yield put(Actions.fetchSettingsSucceeded(response));
      } else {
        yield put(GlobalActions.errorOccured(response));
      }
    } catch (err) {
      yield put(GlobalActions.networkErrorOccured(err));
    }
  });

const updateProductStock = ({ article }) =>
  wrapByLoader(function* () {
    try {
      const response = yield call(Apis.updateProductStockApi, article);
      if (!response.error) {
        yield put(Actions.updateProductStockSucceeded(response));
      } else {
        yield put(GlobalActions.errorOccured(response));
      }
    } catch (err) {
      yield put(GlobalActions.networkErrorOccured(err));
    }
  });

export default [
  takeLatest(ActionTypes.FETCH_SETTINGS, fetchSettings),
  takeLatest(ActionTypes.UPDATE_PRODUCT_STOCK, updateProductStock),
];
