import { put } from "redux-saga/effects";
import * as GlobalActions from "../actions";

export const getViewport = () => {
  const element = window;
  const dimension = {
    width: element.innerWidth,
    height: element.innerHeight,
  };
  return dimension;
};

export function handleErrors(response) {
  // handle for guest user and propmt for login
  if (response.status === 401) {
    // const url = `${window.location.origin}/login`;
    // window.location = url;
    // const noError = {};
    const error = {
      error: {
        code: response.status,
        message: "Auth error",
      },
      status: response.status,
      statusText: response.statusText,
      type: "AuthError",
    };
    return Promise.reject(error);
  }
  if (response.status >= 500) {
    const error = {
      error: {
        code: response.status,
        message: "Network error",
      },
      status: response.status,
      statusText: response.statusText,
    };
    return Promise.reject(error);
  }
  // normal response handling
  return response.json().then((json) => {
    if (!response.ok) {
      const error = Object.assign({}, json, {
        status: response.status,
        statusText: response.statusText,
      });
      return Promise.reject(error);
    }
    return json;
  });
}

export function showMessage(message, info) {
  message.info(info);
}

export const getPostParams = (payload) => ({
  method: "POST",
  headers: {},
  body: JSON.stringify(payload),
});
export const getEmailPostParams = (payload) => ({
  method: "POST",
  headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
  body: JSON.stringify(payload),
});

export const getFormDataPostParams = (payload) => ({
  method: "POST",
  headers: {},
  body: payload,
});

export const getFormDataPutParams = (payload) => ({
  method: "PUT",
  body: payload,
});

export const getFormDataParams = (payload) => ({
  method: "POST",
  body: payload, // formdata
});

export const getGetParams = () => ({
  method: "GET",
  headers: {},
});

export const getDeleteParams = () => ({
  method: "DELETE",
  headers: {},
});

export const getPutParams = (payload) => {
  return {
    method: "PUT",
    headers: {},
    body: JSON.stringify(payload),
  };
};

export const getPatchParams = (payload) => ({
  method: "PATCH",
  headers: {},
  body: JSON.stringify(payload),
});

const handleCatch = (err) => {
  if (err.toString().indexOf("TypeError") > -1) {
    return {
      error: {
        code: "",
        message: err.toString(),
      },
      status: "",
      statusText: "",
    };
  }
  return err;
};

export const Api = {
  fetch: (url, params) => {
    if (!url) {
      throw new Error("Uh-oh, url not found");
    }
    return fetch(url, params).then(handleErrors).catch(handleCatch);
  },
};

export function* wrapByLoader(fn) {
  yield put(GlobalActions.fetchStart());
  yield fn();
  yield put(GlobalActions.fetchEnd());
}

export function* wrapWithoutLoader(fn) {
  yield fn();
}

// check for number with 2 digit decimal
export const isNumeric = (value) => {
  const numRegExPattern = "^\\s*((\\d+(\\.\\d{0,2})?)|((\\d*(\\.\\d{1,2}))))\\s*$";
  const numRegExre = new RegExp(numRegExPattern);
  return numRegExre.test(value);
};
// check for number with 2 digit decimal
export const isInteger = (value) => {
  const numRegExre = new RegExp(/^[0-9]\d*$/);
  return numRegExre.test(value);
};

export const imageBaseUrl = "https://www.pfconcept.com/portal/prodimage/500x500";


export function validateEmail(email) {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(String(email).toLowerCase());
}
