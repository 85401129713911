import ActionTypes from "../../../constants";

const initialState = {
  error: false,
  errorData: {
    type: "error",
    body: "",
    networkError: "Network error occured. Please contact your system admin for support.",
    err: null,
  },
};

const err = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ERROR_OCCURED: {
      // const err = action.err.error;

      const status = action.err.status;
      let body = "Oops, something went wrong!";
      if (action.err && action.err.type === "fileDownloadError") {
        body = "File not found ! ";
      }
      // hard coded handler for upload excel error
      if (action.err && action.err.error && action.err.error.errorType === "excel") {
        body = action.err.error.statusMessage;
      }
      if (action.err && action.err.error && action.err.error.errorType === "custom") {
        body = action.err.error.statusMessage;
      }
      if (action.err && action.err.type === "AuthError") {
        body = "Session expired. Browser will refresh to resume session in 5 seconds";
      }
      const errObj = {
        title: "Error",
        status,
        body,
      };
      const errorData = { ...state.errorData, ...errObj };
      return { ...state, errorData, error: true };
    }
    case ActionTypes.SILENT_ERROR_OCCURED: {
      const status = action.err.status;
      let body = action.err.error.message;

      const errObj = {
        title: "Error",
        status,
        body,
      };
      const errorData = { ...state.errorData, ...errObj };
      return { ...state, errorData, error: false };
    }
    // case 'NETWORK_ERROR_OCCURED': {
    //   const errObj = {
    //     title: 'Network Error',
    //     status: '',
    //     body: action.err.toString(),
    //   };
    //   const errorData = { ...state.errorData, ...errObj };
    //   errorData.body = errorData.networkError;
    //   return { ...state, errorData, error: true };
    // }
    case ActionTypes.RESET_ERROR: {
      return { ...state, error: false };
    }
    default:
      return state;
  }
};

export default err;
