import ActionTypes from "../../../constants";

export const sendEmail = (payload, products) => ({
  type: ActionTypes.SEND_EMAIL,
  payload,
  products
});
export const sendEmailSucceeded = (products) => ({
  type: ActionTypes.SEND_EMAIL_SUCCEEDED,
  products
});
export const resetScanEmailSucceededFlag = () => ({
  type: ActionTypes.RESET_SEND_EMAIL_SUCCEEDED_FLAG,
});
