import ActionTypes from "../../../constants";

export const fetchProduct = (articleId) => ({
  type: ActionTypes.FETCH_PRODUCT,
  articleId,
});
export const fetchProductSucceeded = (product) => ({
  type: ActionTypes.FETCH_PRODUCT_SUCCEEDED,
  product,
});

export const resetProductDetails = () => ({
  type: ActionTypes.RESET_PRODUCT,
});
