import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";

export default function Loader() {
  return (
    <div className="loader-wrapper">
      <div className="loader">
        <div style={{ textAlign: "center" }}>
          <FontAwesomeIcon color="#01447b" icon={faCircleNotch} spin size="2x" />
          <div style={{ padding: 10 }}>loading...</div>
        </div>
      </div>
    </div>
  );
}
