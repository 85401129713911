import ActionTypes from "../../../constants";

const initialState = {
  settings: null,
};

const products = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_SETTINGS_SUCCEEDED: {
      const { settings } = action;
      return { ...state, settings };
    }
    case ActionTypes.UPDATE_PRODUCT_STOCK_SUCCEEDED: {
      const { settings } = action;
      return { ...state, settings };
    }
    default: {
      return state;
    }
  }
};

export default products;
