import ActionTypes from "../../../constants";

export const fetchSettings = () => ({
  type: ActionTypes.FETCH_SETTINGS,
});
export const fetchSettingsSucceeded = (settings) => ({
  type: ActionTypes.FETCH_SETTINGS_SUCCEEDED,
  settings,
});
export const updateProductStock = (article) => ({
  type: ActionTypes.UPDATE_PRODUCT_STOCK,
  article,
});
export const updateProductStockSucceeded = (settings) => ({
  type: ActionTypes.UPDATE_PRODUCT_STOCK_SUCCEEDED,
  settings,
});
