import urls from "../../config/urls";
import { Api, getGetParams, getPatchParams } from "../../utils";

export function fetchSettingsApi() {
  return Api.fetch(urls.products, getGetParams());
}

export function updateProductStockApi(article) {
  return Api.fetch(urls.stock, getPatchParams(article));
}
