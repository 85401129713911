import { takeLatest, put, call } from "redux-saga/effects";
import "whatwg-fetch";
import * as GlobalActions from "../../actions";
import * as Actions from "./store/actions";
import ActionTypes from "../../constants";
import * as Apis from "./apis";
import { wrapByLoader } from "../../utils";

const fetchProduct = ({ articleId }) =>
  wrapByLoader(function* () {
    try {
      const response = yield call(Apis.fetchProductApi, articleId);
      if (!response.error) {
        yield put(Actions.fetchProductSucceeded(response));
      } else {
        yield put(GlobalActions.errorOccured(response));
      }
    } catch (err) {
      yield put(GlobalActions.networkErrorOccured(err));
    }
  });

export default [takeLatest(ActionTypes.FETCH_PRODUCT, fetchProduct)];
