// @flow
import { all } from "redux-saga/effects";
import homeSagas from "../containers/App/sagas";
import productsSagas from "../containers/Products/sagas";
import productSagas from "../containers/ProductDetails/sagas";
import settingsSagas from "../containers/Settings/sagas";

function* rootSaga() {
  yield all([...homeSagas, ...productsSagas, ...productSagas, ...settingsSagas]);
}

export default rootSaga;
