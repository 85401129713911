export default {
  FETCH_START: "FETCH_START",
  FETCH_END: "FETCH_END",
  UPDATE_VIEWPORT: "UPDATE_VIEWPORT",
  ERROR_OCCURED: "ERROR_OCCURED",
  SILENT_ERROR_OCCURED: "SILENT_ERROR_OCCURED",
  RESET_ERROR: "RESET_ERROR",
  NETWORK_ERROR_OCCURED: "NETWORK_ERROR_OCCURED",
  FETCH_USER_INFO: "FETCH_USER_INFO",
  FETCH_USER_INFO_SUCCEEDED: "FETCH_USER_INFO_SUCCEEDED",
  SET_USER_INFO: "SET_USER_INFO",
  SET_MENU_KEY: "SET_MENU_KEY",
  SEND_EMAIL: "SEND_EMAIL",
  SEND_EMAIL_SUCCEEDED: "SEND_EMAIL_SUCCEEDED",
  RESET_SEND_EMAIL_SUCCEEDED_FLAG: "RESET_SEND_EMAIL_SUCCEEDED_FLAG",

};
